.LandingFooter
{
  width: 100%;
  font-size: 16px;
  color: var(--text-color);
  background-color: #fff;
}
.LandingFooter > .Inner
{
  padding: 12px;
  margin: 0 auto;
  width: 100%;
  max-width: var(--content-max-width);
}

@media (min-width: 1200px) and (min-height: 900px)
{
  .LandingFooter
  {
    font-size: 20px;
  }
}
@media (max-width: 849px)
{
  .LandingFooter
  {
    width: auto;
    padding: 20px var(--screen-padding-h);
  }
  .LandingFooter > .Inner
  {
    font-size: 16px;
    max-width: var(--max-width);
    padding: 0;
  }
  .LandingFooter .LandingFooterContent
  {
    flex-direction: column;
  }
  .LandingFooter .StoreLinkBlock
  {
    display: none;
  }
  .LandingFooter .LandingNavBlock
  {
    grid-template-columns: auto;
    font-size: 16px;
  }
  .LandingFooter .LandingSmallBlock
  {
    padding: 0;
  }
  .LandingFooter .LandingSmallBlock small
  {
    font-size: 10px;
    margin: 16px 0;
  }
}
