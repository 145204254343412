.DocNavDialog .DialogContent
{
  box-shadow: 0 0 1px 0 var(--dim-color);
  max-width: none;
  font-size: 16px;
}
.DocNavDialog .DialogHead
{
  padding: 8px;
}
.DocNavDialog .DialogHead .Button,
.DocNavDialog .DialogHead .Heading
{
  font-size: inherit;
}
.DialogMenu .MenuItem
{
  padding: 10px 4px;
}
.DocNavDialog[aria-hidden]
{
  transform: translateX(-100%);
  opacity: 1;
}
