.DocContent
{
  font-size: 12px;
  line-height: 1.33;
  word-break: break-word;
}
.DocContent :link:hover,
.DocContent :link:focus
{
  text-decoration: underline;
}
.DocContent h2
{
  font-size: inherit;
  font-weight: 500;
  margin: 0;
  padding-top: 1.2em;
  display: inline-block;
}
.DocContent strong
{
  font-weight: 600;
}
.DocContent ol,
.DocContent ol ul
{
  list-style: none;
  padding-left: 0;
}
.DocContent ol ul > li::before
{
  content: '- ';
}
.DocContent ol
{
  counter-reset: DocContent;
}
.DocContent ol > li
{
  counter-increment: DocContent;
  margin: 1em 0;
}
.DocContent ol > li::before
{
  content: counters(DocContent, '.') ' ';
}
.DocContent > ol
{
  padding: 0;
}
.DocContent > ol > li::before
{
  content: counters(DocContent, '.') '. ';
  font-weight: 500;
}
.DocContent > ul
{
  padding-left: 1.6em;
}
.DocContent > ul > li
{
  margin: 1.2em 0;
}
.DocContent img
{
  max-width: 600px;
  width: 100%;
}
@media print
{
  .DocContent
  {
    font: 10px 'Times New Roman', serif;
    text-align: justify;
  }
}
