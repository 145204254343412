.WelcomeScreenshot
{
  background-color: transparent;
  background-image: url(media/mockup_880.webp);
  width: 330px;
  height: 501px;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .WelcomeScreenshot
  {
    width: 440px;
    height: 668px;
  }
}
