.CookieDialog
{
  color: #fff;
  position: fixed;
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 16px;
  bottom: 70px;
  z-index: 10;
}
.CookieDialog > .Content
{
  background-color: var(--accent-color);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-column-gap: 24px;
  width: var(--content-max-width);
}
.CookieDialog .Heading
{
  margin: 0;
  text-align: center;
  font-size: inherit;
}
.CookieDialog .Link
{
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}
.CookieDialog .Button
{
  font-size: inherit;
  font-weight: 500;
}
.CookieDialog .Button > .Control
{
  border: none;
  padding: 6px 16px;
}
.CookieDialog[aria-hidden=true]
{
  display: none;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .CookieDialog
  {
    font-size: inherit;
  }
}
@media (max-width: 849px)
{
  .CookieDialog
  {
    bottom: 0;
    box-sizing: border-box;
    font-size: 12px;
  }
  .CookieDialog > .Content
  {
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border-radius: 0;
  }
  .CookieDialog .Button
  {
    margin: 8px 0;
    font-size: 16px;
    width: 100%;
    max-width: 244px;
  }
}
