.WelcomeMain
{
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  width: var(--content-max-width);
  gap: 30px;
  margin: 0 auto;
}
.WelcomeMain > .Content
{
  max-width: 630px;
}
.WelcomeMain .Heading
{
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 40px;
}
.WelcomeMain .Paragraph
{
  font-size: 20px;
  margin: .5em 0;
  font-weight: 200;
}
.WelcomeMain .StoreLinkBlock
{
  margin-top: 60px;
  display: inline-grid;
}
.WelcomeMain .StoreLink
{
  font-size: 14px;
  min-width: 172px;
}
.WelcomeMain .Button.accent
{
  width: 100%;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
  display: none;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .WelcomeMain
  {
    gap: 90px;
  }
  .WelcomeMain > .Content
  {
    max-width: var(--max-width);
    width: 100%;
  }
  .WelcomeMain .Heading
  {
    font-size: 60px;
    margin-bottom: 50px;
  }
  .WelcomeMain .Paragraph
  {
    font-size: 32px;
    margin: initial;
  }
  .WelcomeMain .StoreLinkBlock
  {
    margin-top: 120px;
  }
  .WelcomeMain .StoreLink
  {
    font-size: inherit;
    min-width: 196px;
  }
}
@media (max-width: 849px)
{
  .WelcomeMain
  {
    grid-template-columns: auto;
    width: 100%;
    max-width: var(--max-width);
  }
  .WelcomeMain .Heading
  {
    font-size: 24px;
    margin: 20px 0;
  }
  .WelcomeMain .Paragraph
  {
    font-size: 14px;
    margin: 1em 0;
  }
  .WelcomeMain .StoreLinkBlock
  {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 20px 0;
  }
  .WelcomeMain .StoreLink
  {
    min-width: 100px;
    font-size: 12px;
    justify-content: center;
  }
  .WelcomeMain .Button.accent
  {
    display: block;
  }
}
