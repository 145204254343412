.WelcomeScreen
{
  --screen-padding-h: 16px;

  color: #fff;
  min-height: 100vh;
}
.WelcomeScreen .LandingHeader .NomadLogo
{
  background-image: url(NomadLogo_white.png);
}
@media (max-width: 849px)
{
  .WelcomeScreen .LandingHeader
  {
    flex-direction: column;
    padding: 50px 0 0;
  }
  .WelcomeScreen .LandingHeader .Button
  {
    display: none;
  }
  .WelcomeScreen .WelcomeScreenshot
  {
    display: none;
  }
}
