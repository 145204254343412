.LandingSmallBlock
{
  padding: 10px 0;
  max-width: 1270px;
}
.LandingSmallBlock a[href]
{
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}
.LandingSmallBlock small
{
  display: block;
  margin: 10px 0;
  font-size: 13px;
}
