.StoreLink
{
  display: inline-flex;
  gap: 12px;
  align-items: center;
  color: #fff;
  background-color: #000;
  border-radius: var(--border-radius);
  padding: .375em 1em .375em .5em;
  box-sizing: border-box;
}
.StoreLink .Icon
{
  font-size: 1.5625em;
}
.StoreLink .Label
{
  font-size: inherit;
  font-weight: 500;
  cursor: pointer;
}
.StoreLink .Div
{
  font-size: 1.25em;
  white-space: nowrap;
}
.StoreLink .Icon_logo-apple::before,
.StoreLink .Icon_logo-rustore::before,
.StoreLink .Icon_logo-huawei::before
{
  background-size: 80%;
}
