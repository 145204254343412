.LandingHeader
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: var(--content-max-width);
  padding: 4px 12px;
  margin: 0 auto;
}
.LandingHeader .NomadLogo
{
  --logo-width: 300px;
}
.LandingHeader .Button
{
  font-size: 18px;
  font-weight: 600;
}
.LandingHeader .ButtonControl
{
  padding: 5px 24px;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .LandingHeader .NomadLogo
  {
    --logo-width: 470px;
  }
  .LandingHeader .Button
  {
    font-size: 20px;
  }
  .LandingHeader .ButtonControl
  {
    padding: 7px 32px;
  }
}
@media (max-width: 849px)
{
  .LandingHeader
  {
    position: sticky;
    top: 0;
  }
  .LandingHeader .NomadLogo
  {
    --logo-width: 264px;
  }
}
