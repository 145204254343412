.DocMainBlock
{
  --tree-width: 154px;
  --gap-h: 20px;
  --padding-h: 12px;
  --padding-v: 12px;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--gap-h);
  align-items: flex-start;
  width: 100%;
  max-width: calc(var(--content-max-width) + var(--padding-h) * 2 + var(--gap-h) * 2 + var(--tree-width) * 2);
  padding: var(--padding-v) var(--padding-h);
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}
.DocMainBlock .DocNavTree
{
  position: sticky;
  top: 0;
}
.DocMainBlock .LoadingIndicator
{
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 40px;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .DocMainBlock
  {
    --tree-width: 300px;
    --gap-h: 30px;
    --padding-h: 20px;

    font-size: 24px;
  }
}
