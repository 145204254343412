.LandingNavBlock
{
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px 30px;
  font-size: 14px;
}
.LandingNavBlock .Link
{
  color: inherit;
  font-weight: 500;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .LandingNavBlock
  {
    font-size: 20px;
  }
}
