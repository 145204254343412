.DocNavMenu
{
  display: flex;
  gap: 20px;
  font-size: inherit;
}
.DocNavMenu .MenuItem
{
  padding: .35em 0;
  font-size: inherit;
  color: var(--dim-color);
  font-weight: 500;
  cursor: pointer;
}
.DocNavMenu .MenuItem:not([href])
{
  color: var(--color);
  cursor: default;
}
