.DocDocument
{
  overflow: auto;
  padding-bottom: 20px;
}
.DocDocument .DocContent
{
  font-size: inherit;
}
@media (max-width: 649px)
{
  .DocDocument
  {
    padding-right: 8px;
  }
}
