.DocScreen
{
  --screen-padding-h: 16px;

  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}
.DocScreen > .BodyBlock
{
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
.DocScreen .LandingHeader
{
  margin: 0 auto;
  background-color: var(--bg-color);
  z-index: 1;
}
.DocScreen .Button[type=menu]
{
  display: none;
}
@media (max-width: 1860px) and (min-height: 900px)
{
  .DocScreen .LandingHeader,
  .DocScreen .LandingFooter > .Inner
  {
    max-width: none;
    box-sizing: border-box;
  }
}
@media (max-width: 1170px) and (max-height: 899px)
{
  .DocScreen .LandingHeader,
  .DocScreen .LandingFooter > .Inner
  {
    max-width: none;
    box-sizing: border-box;
  }
}
@media (max-width: 849px)
{
  .DocScreen .LandingHeader
  {
    padding: var(--screen-padding-h);
    border-bottom: 1px solid #e7e7e7;
  }
  .DocScreen .LandingHeader .Button.accent
  {
    font-size: 16px;
    line-height: 24px;
  }
  .DocScreen .NomadLogo
  {
    display: none;
  }
  .DocScreen .DocNavTree
  {
    display: none;
  }
  .DocScreen .DocMainBlock
  {
    grid-template-columns: auto;
    padding: 0;
  }
  .DocScreen .DocMainBlock > .ContentBlock
  {
    overflow: auto;
  }
  .DocScreen .Button[type=menu]
  {
    display: inline-block;
  }
  .DocScreen .DocNavMenu
  {
    display: none;
  }
  .DocScreen .DocMain
  {
    padding: 16px var(--screen-padding-h);
    max-width: none;
  }
  .DocScreen .DocHeadBlock
  {
    font-size: 16px;
    margin: 12px 0;
    padding: 0;
  }
  .DocScreen .DocHeadBlock[hidden]
  {
    display: block;
  }
  .DocScreen .DocDocument
  {
    padding: 0;
  }
  .DocScreen .LandingFooter
  {
    width: 100%;
    box-sizing: border-box;
  }
  .DocScreen .LandingFooter > .Inner
  {
    max-width: none;
  }
}
