.DocHeadBlock
{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 12px;
  align-items: center;
  padding-top: 1.2em;
}
.DocHeadBlock[hidden]
{
  display: none;
}
.DocHeadBlock .Heading
{
  margin: 0;
  font-size: inherit;
  font-weight: 500;
}
.DocHeadBlock .DocArchiveLink
{
  display: none;
}
