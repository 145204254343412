.LandingApp
{
  --content-max-width: 800px;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .LandingApp
  {
    --content-max-width: 1160px;
  }
}
@media (max-width: 849px)
{
  .LandingApp
  {
    --max-width: 440px;
  }
}
