.LandingContactsBlock
{
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
}
.LandingContactsBlock a[href]
{
  color: inherit;
}
.LandingContactsBlock .Group
{
  display: flex;
  gap: 12px;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .LandingContactsBlock .Group
  {
    gap: 20px;
  }
}
@media (max-width: 849px)
{
  .LandingContactsBlock
  {
    margin: 10px 0;
    flex-direction: column-reverse;
    gap: 20px;
    text-align: left;
  }
  .LandingContactsBlock .Group
  {
    margin-left: -4px;
  }
  .LandingContactsBlock .Icon
  {
    font-size: 20px;
  }
}
