.StoreLinkBlock
{
  font-size: 16px;
  display: grid;
  gap: 12px;
  /*grid-template-columns: 1fr 1fr;*/
  grid-template-columns: 1fr;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .StoreLinkBlock
  {
    gap: 22px;
  }
}
@media (max-width: 849px)
{
  .StoreLinkBlock
  {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .StoreLinkBlock .StoreLink
  {
    padding: 6px 20px;
  }
  .StoreLinkBlock .Icon
  {
    font-size: 12px;
  }
  .StoreLinkBlock .Label
  {
    margin: 0;
    font-size: 14px;
  }
  .StoreLinkBlock .Div
  {
    font-size: inherit;
    display: inline;
    margin-left: 5px;
  }
}
