.DocMain
{
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: var(--content-max-width);
  height: 100%;
  overflow: auto;
  padding-top: 1px;
  box-sizing: border-box;
}
