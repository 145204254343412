.LandingFooterContent
{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.LandingFooterContent .StoreLinkBlock
{
  font-size: 12px;
}
.LandingFooterContent .StoreLink
{
  min-width: 150px;
  padding-top: 2px;
  padding-bottom: 2px;
}
