.DocNavTree
{
  font-weight: 500;
  width: var(--tree-width);
  padding-bottom: .65em;
}
.DocNavTree .Link
{
  display: block;
  padding: .35em 0;
}
.DocNavTree .Link[href]
{
  color: var(--dim-color);
}
.DocNavTree .Link:not([href])
{
  cursor: default;
}
.DocNavTree .Group
{
  padding-left: 6px;
}
.DocNavTree .Group.list .Link
{
  display: list-item;
  list-style: none;
  counter-increment: DocNavTree;
}
.DocNavTree .Group.list .Link::before
{
  content: counter(DocNavTree) '. ';
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .DocNavTree .Group
  {
    padding-left: 12px;
  }
}
