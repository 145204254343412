@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
@import 'xuick/lib/root.css';

:root
{
  --min-width: 320px;
  --max-width: 650px;
  --card-box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
:link
{
  color: var(--link-color);
  text-decoration: none;
}
:visited
{
  color: var(--visited-color);
}
html
{
  font: 300 14px/1.4 'Manrope', sans-serif;
  min-height: 100vh;
}
body
{
  color: var(--text-color);
  margin: 0 auto;
  min-height: 100vh;
  min-width: var(--min-width);
  background-color: var(--bg-color);
}
