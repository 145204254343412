.WelcomeBodyBlock
{
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 30px;
  min-height: 100vh;
  padding-top: 12px;
  margin: 0 auto;
  box-sizing: border-box;
  background: url(media/landscape_1600.webp) center center / cover no-repeat;
}
.WelcomeBodyBlock::before
{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: .3;
}
.WelcomeBodyBlock > *
{
  position: relative;
  z-index: 1;
}
.WelcomeBodyBlock .LegalConfirmationBlock
{
  text-align: center;
  font-weight: 200;
  padding: 30px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 1200px) and (min-height: 900px)
{
  .WelcomeBodyBlock
  {
    background-image: url(media/landscape_2400.webp);
  }
  .WelcomeBodyBlock .LegalConfirmationBlock
  {
    font-size: 20px;
  }
}
@media (max-width: 849px)
{
  .WelcomeBodyBlock
  {
    padding: 0 var(--screen-padding-h);
    background-image: url(media/landscape_1200.webp);
  }
  .WelcomeBodyBlock::before
  {
    opacity: .3;
  }
  .WelcomeBodyBlock .LegalConfirmationBlock
  {
    font-size: 12px;
    max-width: var(--max-width);
    padding: 20px;
  }
}
